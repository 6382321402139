import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="fixed top-4 left-4 right-4 bg-gray-800 bg-opacity-80 text-white p-4 rounded-lg z-50 h-16"> {/* Set fixed height here */}
      <div className="container mx-auto px-4 flex justify-between items-center h-full"> {/* Ensure the div fills the full height */}
        
        {/* Replace text with logo image */}
        <Link to="/">
          <img 
            src="/media/logo.png" 
            alt="alx.edits Logo" 
            className="h-12 w-auto"
          />
        </Link>

        {/* Links for larger screens */}
        <div className="hidden md:flex space-x-4">
          <Link to="/portfolio" className="font-bold">Portfolio</Link>
          <Link to="/contact" className="font-bold">Contact</Link>
        </div>

        {/* Menu button for smaller screens */}
        <button 
          className="md:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          Menu
        </button>
      </div>

      {/* Dropdown menu for smaller screens */}
      {isMenuOpen && (
        <div className="md:hidden mt-4">
          <Link to="/portfolio" className="block py-2 font-bold">Portfolio</Link>
          <Link to="/contact" className="block py-2 font-bold">Contact</Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
