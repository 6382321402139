import React, { useState, useEffect } from 'react';

const Home: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    '/media/bg1.jpg',
    '/media/bg2.jpg',
    '/media/bg3.jpg',
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3500);

    return () => clearInterval(timer);
  }, [slides.length]);

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className="bg-gray-900 text-white">
      <section id="home" className="h-screen relative overflow-hidden">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 bg-cover bg-center bg-no-repeat transition-opacity duration-1000 ${
              index === currentSlide ? 'opacity-100' : 'opacity-0'
            }`}
            style={{ backgroundImage: `url(${slide})` }}
          />
        ))}
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <h1 className="text-4xl md:text-6xl font-bold px-4 text-center">alx.edits</h1>
        </div>
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {slides.map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full ${
                index === currentSlide ? 'bg-white' : 'bg-gray-400'
              }`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </section>

      <section id="about" className="py-20 relative">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl md:text-5xl font-bold mb-16 text-center">About Me</h2>
          <div className="max-w-4xl mx-auto">
            <div className="space-y-6">
              <p className="text-xl leading-relaxed">
                Hey, I'm Alex. I'm 18 and have been passionate about photography and videography for a couple of years. My journey began with shooting cars for Instagram, which remains a key focus. I also take on some real estate projects to diversify my portfolio.
              </p>
              <p className="text-xl leading-relaxed">
                Whether capturing stunning cars or beautiful properties, I aim to create high-quality visuals that tell compelling stories. I believe in simplicity—just clean, striking images that truly stand out.
              </p>

              <div className="mt-12 text-center">
                <a href="/contact" className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 text-lg mr-4">
                  Contact me!
                </a>
                <a href="/portfolio" className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 text-lg">
                  See Photos
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="py-20 bg-gray-900">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl md:text-5xl font-bold mb-16 text-center">What I do</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">

            <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300">
              <video className="w-full h-48 object-cover" autoPlay muted loop>
                <source src="/media/750.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-4">Car Photography & Videography</h3>
                <p className="text-gray-300 mb-4">
                  I specialize in car photography and videography that brings vehicles to life. With sharp editing and dynamic shots, I capture the spirit of each car, ensuring it looks its best for you or your brand.
                </p>
                <a href="portfolio" className="text-blue-400 hover:text-blue-300 font-semibold">See Photos →</a>
              </div>
            </div>

            <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300">
              <video className="w-full h-48 object-cover" autoPlay muted loop>
                <source src="/media/mansion.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="p-6">
                <h3 className="text-2xl font-semibold mb-4">Real Estate Photography & Videography</h3>
                <p className="text-gray-300 mb-4">
                  I create eye-catching photos and videos of properties that attract potential buyers. Through thoughtful editing and great lighting, I highlight each space's best features, making them feel inviting and memorable.
                </p>
                <a href="contact" className="text-blue-400 hover:text-blue-300 font-semibold">Contact →</a>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
