import React from 'react';

const Portfolio: React.FC = () => {
  const carShoot1 = [
    { src: '/media/portfolio/shoot1/1.mp4', type: 'video' },
    { src: '/media/portfolio/shoot1/2.jpg', alt: 'Car Shoot 1 - Image 2', type: 'image' },
    { src: '/media/portfolio/shoot1/3.jpg', alt: 'Car Shoot 1 - Image 3', type: 'image' },
    { src: '/media/portfolio/shoot1/4.jpg', alt: 'Car Shoot 1 - Image 4', type: 'image' },
    { src: '/media/portfolio/shoot1/5.jpg', alt: 'Car Shoot 1 - Image 5', type: 'image' },
    { src: '/media/portfolio/shoot1/6.jpg', alt: 'Car Shoot 1 - Image 6', type: 'image' },
    { src: '/media/portfolio/shoot1/7.jpg', alt: 'Car Shoot 1 - Image 7', type: 'image' },
    { src: '/media/portfolio/shoot1/8.jpg', alt: 'Car Shoot 1 - Image 8', type: 'image' },
    { src: '/media/portfolio/shoot1/9.jpg', alt: 'Car Shoot 1 - Image 9', type: 'image' },
  ];

  const carShoot2 = [
    { src: '/media/portfolio/shoot2/1.jpg', alt: 'Car Shoot 2 - Image 1', type: 'image' },
    { src: '/media/portfolio/shoot2/2.jpg', alt: 'Car Shoot 2 - Image 2', type: 'image' },
    { src: '/media/portfolio/shoot2/3.jpg', alt: 'Car Shoot 2 - Image 3', type: 'image' },
    { src: '/media/portfolio/shoot2/4.jpg', alt: 'Car Shoot 2 - Image 4', type: 'image' },
    { src: '/media/portfolio/shoot2/5.jpg', alt: 'Car Shoot 2 - Image 5', type: 'image' },
    { src: '/media/portfolio/shoot2/6.jpg', alt: 'Car Shoot 2 - Image 6', type: 'image' },
    { src: '/media/portfolio/shoot2/7.jpg', alt: 'Car Shoot 2 - Image 7', type: 'image' },
    { src: '/media/portfolio/shoot2/8.jpg', alt: 'Car Shoot 2 - Image 8', type: 'image' },
    { src: '/media/portfolio/shoot2/9.jpg', alt: 'Car Shoot 2 - Image 9', type: 'image' },
  ];

  return (
    <div className="min-h-screen mt-20">
      <div className="flex flex-wrap justify-center items-center gap-4 p-6">
        {carShoot1.map((media, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-80 p-2 transition-transform transform hover:scale-105 hover:shadow-lg"
          >
            {media.type === 'video' ? (
              <video
                src={media.src}
                className="w-full h-auto object-cover rounded-lg"
                autoPlay
                loop
                muted
                controls={false}
              />
            ) : (
              <img
                src={media.src}
                alt={media.alt}
                className="w-full h-auto object-cover rounded-lg"
              />
            )}
          </div>
        ))}
      </div>

      <hr className="my-8 border-gray-300" />

      <div className="flex flex-wrap justify-center items-center gap-4 p-6">
        {carShoot2.map((media, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-80 p-2 transition-transform transform hover:scale-105 hover:shadow-lg"
          >
            {media.type === 'video' ? (
              <video
                src={media.src}
                className="w-full h-auto object-cover rounded-lg"
                autoPlay
                loop
                muted
                controls={false}
              />
            ) : (
              <img
                src={media.src}
                alt={media.alt}
                className="w-full h-auto object-cover rounded-lg"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
